<template>
  <div id="home">
    <el-container style="height: 100vh;width:100%">
      <el-row >
            <el-col :span="12" :offset="1" style="text-align: left;line-height:40px">北京怪谈社</el-col>
            <el-col v-if="userId && userId != 'null'&& phone && phone != 'null'" :span="10" style="text-align: right;">
              <span style="margin-right:20px">用户_{{phone.substr(-4)}}</span>
              <el-button type="text" class="button" @click="logout()">退出登录</el-button>
            </el-col>
            <el-col v-else :span="10" style="text-align: right;">
              <el-button type="text" class="button" @click="onLoginClick(1)">登录</el-button>
              <el-button type="text" class="button" @click="onLoginClick(0)">注册</el-button>
            </el-col>
      </el-row>
      <el-header>
        <el-menu style="display: inline-block;" :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
          @select="handleSelect">
          
          <el-menu-item index="1">主页</el-menu-item>
          <el-menu-item index="2">付费资讯</el-menu-item>
          <!-- <el-submenu index="2">
            <template slot="title">分类</template>
            <el-menu-item index="杀人">真实罪案</el-menu-item>
            <el-menu-item index="医生">医院故事</el-menu-item>
          </el-submenu> -->
          <el-menu-item index="3">关于我们</el-menu-item>
        </el-menu>
      </el-header>
      <el-main>
        <div v-if="activeIndex == '3'">
          <div class="about">
            <div class="about-title">
              <div style="padding-top: 150px">
                <h3>关于我们</h3>
              </div>
              <div>捕捉最带劲儿的亲身经历</div>
            </div>
            <div class="about-content">
              北京怪谈社成立于2016-12-14，所属行业为文化艺术业，经营范围包含：一般项目:组织文化艺术交流活动;会议及展览服务;文艺创作;广告发布;广告设计、代理;工艺美术品及收藏品零售（象牙及其制品除外）;专业设计服务;图文设计制作;翻译服务;企业管理咨询;企业形象策划;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广;电影摄制服务;数字内容制作服务（不含出版发行）;平面设计;包装服务;企业管理;社会经济咨询服务;版权代理;软件外包服务;软件开发;计算机系统服务;体育用品及器材零售;日用杂品销售;日用化学产品销售;日用品销售;乐器零售;乐器零配件销售;互联网销售（除销售需要许可的商品）;化妆品零售;服装服饰零售;服装服饰批发;玩具销售;玩具、动漫及游艺用品销售;鞋帽零售;鞋帽批发。（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）许可项目:广播电视节目制作经营;出版物零售;出版物互联网销售;出版物批发;电子出版物制作;演出经纪;互联网信息服务;音像制品制作;网络文化经营;电影发行;第二类增值电信业务。（依法须经批准的项目,经相关部门批准后方可开展经营活动,具体经营项目以相关部门批准文件或许可证件为准）（不得从事国家和本市产业政策禁止和限制类项目的经营活动。）。北京怪谈社文化传媒有限公司目前的经营状态为存续（在营、开业、在册）。
            </div>
          </div>
        </div>
        <div v-else>
          <el-row v-for="(item, index) in dataList" :key="index" style="margin-top: 20px;margin-bottom: 30px;">
            <el-col :span="9" :offset="2" v-for="(subitem) in item" :key="subitem.id">
              <el-card :body-style="{ padding: '0px' }" shadow="hover">
                <div style="width:100%;height:400px;display: block;overflow: hidden;">
                  <img :src="subitem.picUrl" class="image" style="object-fit: cover;width:100%;height:100%">
                </div>
                <div class="card-text">
                  <div style="margin-bottom:20px"><strong>{{ subitem.title }}</strong></div>
                  <div><span>{{ subitem.content }}</span></div>
                  <div><el-button type="text" class="button" @click="buyHandle(subitem)">查看更多需支付19.9元 →</el-button></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>

        <div class="footer-box">
          <el-row>
            <el-col :span="8"><div>网站备案号: <a href="https://beian.miit.gov.cn" target="_blank">京ICP备2022036161号-1</a></div></el-col>
            <el-col :span="8" style="text-align: left;"><div>北京怪谈社        公司电话：010-64013140</div></el-col>
            <el-col :span="8" style="text-align: left;"><div>公司地址：北京市西城区京版大厦B座1003</div></el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="16" style="text-align: left;"><div>公司地址：北京市西城区京版大厦B座1003</div></el-col>
            <el-col :span="8">
              <a target="_blank"
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051459">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAFHElEQVR4nETUW2wcVx3H8e9cdnZm9jZrr3e9eEOIYzVxaBKXJECLcWhAvaDEFCOFBiFEUSgVqFQg+gAqD1V5KC+RqKoCCkUiVR6AIlUkUWjqGtE2ESlUCSRRLo7jON51fNmLd2d3Ljs7M8iOsP8P5+189D/S73fkMAzBarEyASJiGGArRbRIL9DstW5dGP376X9917U7yhce2Xm0a8eOt6C/6LohUdsGUQDE1fskEwgroGfVQQgR/RBJSQPvPTx5+swzZ/92YzS0J9VZV0dKG2zMqNhlvTP8xf4TgwfGfh/6nzgZuk1CYQUNkVJ99+iGYNIMlwn1NCiOOvHr1ye+9fT4waYzo94XlzisLvCMOstDD+boejAnH376na+eeeXlE4ImRS1FwOHfePJHq0ve21USEaUkslgpuBdeevtXx+scOtzPtwsNUr+9QuW6T2XKp77/N3zFv8Rzx57k6F88wuu/+3Ncd1RRjaPGoutg3DWJReuDd06+MDWy/9KIMZDjB5lLTB9vIG4w8M02uAFCj8G5Z89yUL9A7nMP8KX9pw4sffD6LWvm4par7/51HYy6NrLzdvzE9YTy4dxuHt9t0rzYQLxTRzCiRKs+ztkFIpu6UZs25T+cZ+zRNhM3t/HG+34+LrQ0xKF1sL5ULgT/fPdIIGggWyTNORrzEPE9qDoIm3WUXQbi3RYKAeVpm74uFwSBWlMk2iMf2To8tmkNnLn2ztfapdJwOq5AJ0qYz5MZ7qVJiPlQN/6hAvEXh9Cf24HYo5PdlaFltSEMyWd1KF58uDp77jtroB5PDzZMj/4eB9RuzpzXkfclqRzYiF8yEf7bwjq1gDe9jPL8MF0/2smbx2pAjKHtMq2pRZziVGENDKpzBxo1mfsLJoVPK7z2Rox/vFxkaE+elJ7AulIjWHRxLtVIlJa5/NINXjkWJTdksL2vTrnk4lWvDa+BotwyzIZIcukGBx/16XhxvJPzdP/xI8S+CO0eiSArk9mXJz9xi/LRy7TaEmOjERKVScyGjJwQIyuWvHJo+sfHxS23R/1cH099xuRIXw+vlQbxZq/QXZlETGnYV2qYA1lK6Tx/EjVIGjz12UU6iQLGJwX09IZTayCI2bARMjdlcv/gNL/4yS5eeH6Ek+Z2aHiMWhW6DJE3TyVoogNpfvx9lT0Dk8xNAo5F0DF3roGy0d7dSeTw5j2Ki1f54XCUsfEYE6ctrok7eGzbTfJamc6JPto1mw1bdX722G0Wzt3G8lTUjIJsKFtXrNXPwW1++I3K+V8et+fbuGUPijNsfmKIaE4Fv4JfkWku2qQ2dcHGbiiWmX7rOnYiQ7w/jtarkxo4tFdJf/m9VXBlOq3/fLN6+cirvmOnyu/PI8dCevcO4ldtPKuFqKuoXTEEJULl3BQdXya1ezOeQyW1ZeTJmJEcF5RH7j3Zs2ewl+ySM6/Zai5I5fYN4Ndb+GYDdAVJMVA0aC83aRVrqBu7UQs53GULp2SG2oYmUjKJ9v/YhO1bSHzw0+ynEr2RdB63HhD4MoEv4SyZEAa0K3Wak0sIUmS1cs7dMhFNJPf5fEbRze8FNutdVqQk0e49T5hW6lWrWGwp0jLJbIeIYKEIHpK7TGjbJLZmSA4kEZUmckpBSPfe6cgP/FzW7/u6pCyxHpvQQ4jIVqMSe9a+mnvRri897nqzez+2rWdbYApx1RC7ZC2BeZc7Wo9hydmRi9WpxXF7pnamb282ECSJ0HdWqf8FAAD//0EeNxZKMT/rAAAAAElFTkSuQmCC"
                    >
                    <span style="margin-left:5px;color:#939393;" data-v-1bfa8004="">京公网安备
                    </span></a>
                  </el-col>
            
            
          </el-row> -->
        </div>
      </el-main>
    </el-container>

    <el-dialog :title="dialogInfo.title" :visible.sync="dialogInfo.show" :width="dialogInfo.width" append-to-body>
      <canvas id="canvasId" :style="{'width':'100%', 'height':'450px'}"></canvas>
    </el-dialog>


    <el-dialog :title="isLogin ? '请登录' : '请注册'" :visible.sync="dialogFormVisible" width="400px" >
        <el-form ref="loginForm" :model="form" :rules="rules" style="padding-left:40px;padding-right:40px">
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item v-if="!isLogin" label="确认密码" prop="password1">
            <el-input type="password" v-model="form.password1"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="login">确 定</el-button>
        </div>
      </el-dialog>

  </div>
</template>

<script>
import $API from './api/api'
import QRCode from "qrcode";
export default {
  name: 'Home',
  components: {

  },
  data() {
    /* 手机号 */
    var isMobileNumber = (rule, value, callback) => {
      console.log(rule);
      if (!value) {
        return new Error('请输入电话号码')
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        const isPhone = reg.test(value)
        value = Number(value) //转换为数字
        if (typeof value === 'number' && !isNaN(value)) {
          //判断是否为数字
          value = value.toString() //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error('手机号码格式如:138xxxx8754'))
          } else {
            callback()
          }
        } else {
          callback(new Error('请输入电话号码'))
        }
      }
    }
    return {
      isLogin: 1,
      userId: null,
      phone: null,
      activeIndex: '1',
      dataList: [],
      dialogInfo: {
        title: '',
        show: true,
        width: '0px'
      },
      dialogFormVisible: false,
      form: {
        phone: '',
        password: '',
        password1: ''
      },
      isOrder: false,
      /* 校验规则 */
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: isMobileNumber, trigger: 'blur' }
          // { length:13, message: '请输入正确手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 20, message: '用户密码长度必须介于 5 和 20 之间', trigger: 'blur' }
        ],
        password1:  [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { min: 5, max: 20, message: '用户密码长度必须介于 5 和 20 之间', trigger: 'blur' }
        ],
      }
    }
  },
  created() {
    this.loadUser();
    this.loadData();
  },
  mounted() {
    this.dialogInfo.show = false;
  },
  methods: {
    onLoginClick(state) {
      this.isLogin = state;
      this.dialogFormVisible = true;
    },
    async loadUser() {
      this.userId = localStorage.getItem("userId");
      this.phone = localStorage.getItem("phone");
      this.isOrder = localStorage.getItem("isOrder");
      
      // if (this.userId) {
      //   return;
      // }
      // var url = window.location.href;
      // var temp1 = url.split("?");
      // var parm = temp1[1];
      // if (!parm) {
      //   return;
      // }
      // var keyValue = parm.split("&");
      // var obj = {};
      // for (var i = 0; i < keyValue.length; i++) {
      //   var item = keyValue[i].split("=");
      //   var key = item[0];
      //   var value = item[1];
      //   obj[key] = value;
      // }
      // if (!obj.code) {
      //   return;
      // }
      // //alert(JSON.stringify(obj))
      // const result = await $API.login({code:obj.code});
      // //alert(JSON.stringify(result))
      // this.userId = result.data.id;
      // this.openId = result.data.wxOpenId;
    },
    async loadData() {
      var result = null;
      // if (this.activeIndex == '1') {
      //    result = await $API.articleColumnId(84);
      // } else {
         result = await $API.search();
      // }
      var dataList = [];
      if (this.activeIndex == '1') {
        for (var i = 1; i < result.data.records.length; i += 2) {
          dataList.push([result.data.records[i], result.data.records[i - 1]]);
        }
      } else {
        for (var j = result.data.records.length -1; j >= 0; j -= 2) {
          dataList.push([result.data.records[j], result.data.records[j - 1]]);
        }
      }
      
      this.dataList = dataList;
    },
    async buyHandle(item) {
      if (!this.userId || this.userId == 'null') {
        this.userId = null;
        // this.onLoginClick(1)
        // return;
      }
      const buyData = await $API.userOrderStatus({ userId: this.userId, goodsId: item.id });
      console.log();
      if (buyData.flag) {
        this.$confirm('商品已购买，请联系客服010-64013140')
          .then(() => {
          })
          .catch(() => { }); 
        return;
      }
      console.log(item);
      const result = await $API.createOrder({userId: this.userId,goodsId: item.id});
      console.log(result);
      if (!result.data.codeUrl) {
        return;
      }
      this.dialogInfo = {
        title: '请微信扫码支付',
        show: true,
        width: '400px'
      };
      const width = 350, height = 350;
      QRCode.toCanvas(
        document.getElementById('canvasId'),
        result.data.codeUrl,
        { width, height, toSJISFunc: QRCode.toSJIS },
        error => {
          console.log(error);
        }
      );
      // if (!this.userId) {
      //   this.login();
      //   return;
      // }
      // var params = {
      //   name: '文章',
      //   userId: this.userId,
      //   openId: '',
      //   type: 1,
      //   goodsId: item.id
      // }
      // const result = await $API.createOrder(params);
      // console.log(result);
      // // window.open('/detail');
      // const { href } = this.$router.resolve({
      //   path: '/detail?id=' + item.id
      // });
      // window.open(href, '_blank');
     
    },
    login() {
      if (!this.isLogin&& this.form.password != this.form.password1) {
        this.$message.error('密码输入有误');
        return;
      }
      // const appId = 'wx5efd64e786c17a02';
      // const redirect_uri = 'https://www.storyhunting.top';
      // window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${redirect_uri}`
      //window.location.href = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxa5e1ea4e728b710d&redirect_uri=https%3A%2F%2Fpassport.yhd.com%2Fwechat%2Fcallback.do&response_type=code&scope=snsapi_login&state=3d6be0a4035d839573b04816624a415e#wechat_redirect'
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          const result = await $API.login(this.form);
          if (result.data) {
            this.userId = result.data;
            this.phone = this.form.phone;
            localStorage.setItem("userId", this.userId);
            localStorage.setItem("phone", this.form.phone);
            this.dialogFormVisible = false;
          } else {
            this.$message.error('登录失败');
          }
        }
      });
    },
    logout() {
      this.$confirm('确认退出登录？')
          .then(() => {
            this.userId = null;
            localStorage.setItem("userId", null);
            localStorage.setItem("phone", null);
            localStorage.setItem("isOrder", null);
            this.$message({message: '退出成功',type: 'success'});
          })
          .catch(() => {}); 
    },
    handleSelect(key) {
      this.activeIndex = key;
      if (this.activeIndex != '3') {
        this.loadData();  
      }
    },
  }
}
</script>

<style>
.el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
}

.el-main {
  padding: 0px !important;
}

.card-text {
  text-align: left;
  padding: 20px;
}

.about-title {
  height: 400px;
  text-align: center;
  background-color: #ccc;
}

.about-content {
  padding: 36px;
  line-height: 22px;
  font-size: 14px;
}


.footer-box {
  font-size: 14px;
  padding: 0;
  background-color: #000;
  color: #fff;
  padding:20px;
}
a {
  color: #fff;
}

</style>
